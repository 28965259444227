<template>
  <AssetDimensionErrorStatisticsWidget v-if="widget" :widget="widget" />
</template>

<script>
import AssetDimensionErrorStatisticsWidget from '@/components/Atomic/Organisms/Dashboard/AssetDimensionErrorStatisticsWidget'

export default {
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  components: {
    AssetDimensionErrorStatisticsWidget,
  },
}
</script>
