<template>
  <ErrorStatisticsHeadersStyled :columnsCount="headerTiles.length">
    <ErrorStatisticsHeader
      v-for="card in headerTiles"
      :key="card.id"
      :name="card.title"
      :isSelected="card.isSelected"
      :isOverview="card.isOverview"
      :smallTitle="card.smallTitle"
      :value="card.total"
      :unit="card.unit"
      :showUnit="card.showUnit"
      :showIcon="!!card.icon"
      :iconClass="card.icon"
      :trendRed="card.trendRed"
      :showTrendAbsolute="card.showTrendAbsolute"
      :trendAbsolute="card.trendAbsolute"
      :showTrendPercent="card.showTrendPercent"
      :trendPercent="card.trendPercent"
      @select="$emit('selectHeader', card.id)"
    />
  </ErrorStatisticsHeadersStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import ErrorStatisticsHeader from './ErrorStatisticsHeaders/ErrorStatisticsHeader'

const ErrorStatisticsHeadersStyled = styled('div')`
  width: 100%;
  height: 100%;
  grid-area: header;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: repeat(${p => p.columnsCount}, minmax(10rem, 1fr));
  grid-template-rows: 5rem;
  grid-gap: 2px;
  cursor: pointer;
`

export default {
  props: {
    headerTiles: {
      type: Array,
      required: true,
    },
  },
  components: {
    ErrorStatisticsHeader,
    ErrorStatisticsHeadersStyled,
  },
}
</script>
