var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.widget ? _c('AssetDimensionErrorStatisticsWidget', {
    attrs: {
      "widget": _vm.widget
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }