var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MachineComparisonBarChartStyled', {
    attrs: {
      "labelCount": _vm.labelCount,
      "hasNoData": !_vm.showChart
    }
  }, [_vm.showChart ? _c('BarChart', {
    attrs: {
      "option": _vm.option
    }
  }) : _c('NoDataStyled', [_vm._v(_vm._s(_vm.message))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }