<template>
  <StackedHorizontalBarChart :height="height" :hasNoData="!showChart">
    <BarChart :option="option" v-if="showChart" />
    <NoDataStyled v-else>{{ message }}</NoDataStyled>
  </StackedHorizontalBarChart>
</template>
<script>
import chroma from 'chroma-js'
import uniq from 'lodash/uniq'
import { styled } from '@egoist/vue-emotion'
import BarChart from '@/components/Atomic/Atoms/SimpleBarChart'
import localesMixin from '@/mixins/locales'

const StackedHorizontalBarChart = styled('div')`
  width: 100%;
  height: ${p => (p.height > 0 ? p.height + 'vh' : '100%')};
  overflow-x: hidden;
  overflow-y: ${p => (p.height >= 1 ? 'auto' : 'hidden')};
  ${({ hasNoData }) => hasNoData && `display:flex; justify-content:center;align-items: center;`};
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`

export default {
  inject: ['theme'],
  mixins: [localesMixin],
  props: {
    dimensionMapped: {
      type: Object,
      required: true,
    },
    severities: {
      type: Array,
      required: true,
    },
    fetchedData: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      required: false,
    },
  },
  components: {
    StackedHorizontalBarChart,
    BarChart,
    NoDataStyled,
  },
  computed: {
    groupedLabels() {
      let label = []
      if (this.fetchedData) {
        label = this.fetchedData.map(notification => {
          return notification?.label ?? ''
        })
      }
      return uniq(label)
    },
    errorFrequencyLabels() {
      return this.groupedLabels
    },
    errorFrequencyScales() {
      const scales = {
        xAxes: [
          {
            ticks: {
              min: 0,
            },
          },
        ],
        yAxes: [
          {
            display: true,
          },
        ],
      }
      return scales
    },

    errorFrequencyDatasets() {
      let columns = []
      const unitSI = this.dimensionMapped?.assetDimension?.physicalUnitSI ?? null
      const unitUIMetric = this.dimensionMapped?.assetDimension?.physicalUnitUIMetric ?? null
      const unitUIImperial = this.dimensionMapped?.assetDimension?.physicalUnitUIImperial ?? null
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric

      if (this.fetchedData) {
        columns = this.severities.map(severity => {
          const severityData = this.fetchedData.filter(gd => gd.severity === severity)
          const data = this.groupedLabels.map(label => {
            const labelData = severityData.find(sd => sd.label === label)
            if (labelData && labelData.count !== null) {
              return this.convUnit(labelData.count, unitSI, unitUI, 2, false, false)
            } else {
              return '-'
            }
          })
          return {
            name: severity.toLowerCase(),
            label: this.getSeverityLabel(severity),
            backgroundColor: severity,
            data: data,
            borderColor: this.theme.colors.charts.first,
            maxBarThickness: 20,
            minBarThickeness: 10,
            stack: true,
          }
        })
      }
      return columns
    },
    series() {
      return this.errorFrequencyDatasets.map(dataSet => {
        return {
          name: this.$t(`errorStatistics.${dataSet.name}`),
          data: dataSet?.data,
          type: 'bar',
          stack: 'total',
          barMaxWidth: 30,
          emphasis: {
            focus: 'series',
          },
          itemStyle: {
            color: chroma(dataSet.backgroundColor).brighten(0.5).alpha(0.9).css(),
          },
          label: {
            show: true,
            position: 'right',
            color: this.theme.colors.textActivePrimary,
          },
        }
      })
    },
    option() {
      return {
        legend: {
          textStyle: {
            color: this.theme.colors.normalFontColor,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            let tooltipContent = `${params[0].axisValueLabel}`
            params.map(param => {
              tooltipContent += `<br> ${param.marker} ${param.seriesName} :  ${param.data} `
            })
            return tooltipContent
          },
        },
        grid: {
          left: '25',
          right: '15',
          bottom: '15',
          top: '30',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'value',
            nameLocation: 'middle',

            nameTextStyle: {
              color: this.theme.colors.textActivePrimary,
            },
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
            },
            axisLine: {
              show: true,
            },
            splitLine: {
              lineStyle: {
                color: this.theme.colors.atomic.mildgrey,
              },
            },
            nameGap: '20',
          },
        ],
        yAxis: [
          {
            triggerEvent: true,
            type: 'category',
            data: this.errorFrequencyLabels,
            inverse: true,

            axisLabel: {
              color: this.theme.colors.textActivePrimary,
              width: 175,
              overflow: 'truncate',
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
          },
        ],
        series: this.series,
      }
    },
    showChart() {
      const data = this.errorFrequencyDatasets.map(({ data }) => data)
      return data?.length > 0 ?? false
    },
    message() {
      const dimensionNames = this.option?.series?.map(({ name }) => name)?.toString()
      return this.$t('messages.noDimensionData', { dimension: dimensionNames?.split(',').join(', ') })
    },
  },
  methods: {
    getSeverityLabel(severity) {
      return severity === 'Red' ? this.$t('errorStatistics.faults') : this.$t('errorStatistics.warnings')
    },
  },
}
</script>
