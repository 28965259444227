var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ErrorStatisticsHeaderStyled', {
    class: {
      small: _vm.smallTitle,
      icon: _vm.showIcon
    },
    attrs: {
      "showIcon": _vm.showIcon,
      "iconClass": _vm.iconClass,
      "isSelected": _vm.isSelected
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('select');
      }
    }
  }, [_vm.isOverview ? _c('div', {
    staticClass: "name"
  }, [_vm._v("Overview")]) : [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.name))]), _c('div', {
    staticClass: "value"
  }, [_c('span', [_vm._v(_vm._s(_vm.value))]), _vm.showUnit ? _c('span', {
    staticClass: "unit"
  }, [_vm._v(_vm._s(_vm.unit))]) : _vm._e()]), _vm.showIcon ? _c('div', {
    staticClass: "icon"
  }) : _vm._e(), _c('div', {
    staticClass: "trend",
    class: {
      red: _vm.trendRed,
      grey: _vm.trendGrey,
      green: !_vm.trendRed
    }
  }, [_vm.showTrendAbsolute && _vm.showTrendPercent ? [_vm.showTrendAbsolute ? _c('div', {
    staticClass: "absolute"
  }, [_vm._v(_vm._s(_vm.trendAbsolute))]) : _vm._e(), _vm.showTrendPercent ? _c('div', {
    staticClass: "percent both"
  }, [_vm._v(_vm._s(_vm.trendPercent))]) : _vm._e()] : [_vm.showTrendAbsolute ? _c('div', {
    staticClass: "absolute"
  }, [_vm._v(_vm._s(_vm.trendAbsolute))]) : _vm._e(), _vm.showTrendPercent ? _c('div', {
    staticClass: "percent"
  }, [_vm._v(_vm._s(_vm.trendPercent))]) : _vm._e()]], 2)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }