<template>
  <EvolutionOverTimeBarChartStyled :hasNoData="!showChart">
    <BarChart :option="option" v-if="showChart" />
    <NoDataStyled v-else>{{ message }}</NoDataStyled>
  </EvolutionOverTimeBarChartStyled>
</template>

<script>
import chroma from 'chroma-js'
import { styled } from '@egoist/vue-emotion'
import { TimeframeMixin } from '@common/mixins'
import localesMixin from '@/mixins/locales'
import BarChart from '@/components/Atomic/Atoms/SimpleBarChart'
import { DateTime } from 'luxon'

const EvolutionOverTimeBarChartStyled = styled('div')`
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${({ hasNoData }) => hasNoData && `display:flex; justify-content:center;align-items: center;`};
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`
export default {
  inject: ['theme'],
  mixins: [localesMixin, TimeframeMixin],
  props: {
    dimensionMapped: {
      type: Object,
      required: true,
    },
    severities: {
      type: Array,
      required: true,
    },
    fetchedData: {
      type: Array,
      required: true,
    },
  },
  components: {
    EvolutionOverTimeBarChartStyled,
    BarChart,
    NoDataStyled,
  },
  computed: {
    evolutionOverTimeDatasets() {
      let columns = []
      const unitSI = this.dimensionMapped?.assetDimension?.physicalUnitSI ?? null
      const unitUIMetric = this.dimensionMapped?.assetDimension?.physicalUnitUIMetric ?? null
      const unitUIImperial = this.dimensionMapped?.assetDimension?.physicalUnitUIImperial ?? null
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric

      if (this.fetchedData) {
        columns = this.severities.map(severity => {
          const severityData = this.fetchedData.filter(gd => gd.severity === severity)
          const data = this.timeBuckets.map(timeBucket => {
            const found = severityData.find(f => {
              const time = DateTime.fromISO(f.time)
              return timeBucket.contains(time)
            })
            return found !== undefined && found?.count !== null ? this.convUnit(found?.count, unitSI, unitUI, false, false) : '-'
          })
          return {
            label: this.getSeverityLabel(severity),
            backgroundColor: severity,
            data,
            borderColor: this.theme.colors.charts.first,
            maxBarThickness: 20,
          }
        })
      }
      return columns
    },
    evolutionOverTimeLabels() {
      return this.timeBucketsUI
    },
    series() {
      return this.evolutionOverTimeDatasets.map(dataSet => {
        return {
          name: dataSet.label,
          data: dataSet?.data,
          type: 'bar',
          barMaxWidth: 30,
          emphasis: {
            focus: 'series',
          },
          itemStyle: {
            color: chroma(dataSet.backgroundColor).brighten(0.5).alpha(0.9).css(),
          },
        }
      })
    },
    option() {
      return {
        legend: {
          textStyle: {
            color: this.theme.colors.normalFontColor,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            let tooltip = `${params[0].name}`
            const paramFiltered = params.filter(item => item.value !== undefined)
            paramFiltered.forEach(param => {
              tooltip += `<br/> ${param.marker} ${param.seriesName}
                        <B style="float:right;margin-left:1rem">
                          ${param.data}
                        </B>`
            })
            return tooltip
          },
        },
        grid: {
          left: '25',
          right: '15',
          bottom: '15',
          top: '30',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.evolutionOverTimeLabels,
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameLocation: 'middle',
            nameTextStyle: {
              color: this.theme.colors.textActivePrimary,
            },
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
            },
            splitLine: {
              lineStyle: {
                color: this.theme.colors.atomic.mildgrey,
              },
            },
            nameGap: '20',
          },
        ],
        series: this.series,
      }
    },
    showChart() {
      const data = this.evolutionOverTimeDatasets.map(({ data }) => data)
      return data?.length > 0 ?? false
    },
    message() {
      const dimensionNames = this.option?.series?.map(({ name }) => name)?.toString()
      return this.$t('messages.noDimensionData', { dimension: dimensionNames?.split(',').join(', ') })
    },
  },
  methods: {
    getSeverityLabel(severity) {
      return severity === 'Red' ? this.$t('errorStatistics.faults') : this.$t('errorStatistics.warnings')
    },
  },
}
</script>
