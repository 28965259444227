<template>
  <ErrorStatisticsHeaderStyled
    @click="$emit('select')"
    :showIcon="showIcon"
    :iconClass="iconClass"
    :isSelected="isSelected"
    :class="{ small: smallTitle, icon: showIcon }"
  >
    <div v-if="isOverview" class="name">Overview</div>
    <template v-else>
      <div class="name">{{ name }}</div>
      <div class="value">
        <span>{{ value }}</span>
        <span v-if="showUnit" class="unit">{{ unit }}</span>
      </div>
      <div v-if="showIcon" class="icon" />
      <div class="trend" :class="{ red: trendRed, grey: trendGrey, green: !trendRed }">
        <template v-if="showTrendAbsolute && showTrendPercent">
          <div v-if="showTrendAbsolute" class="absolute">{{ trendAbsolute }}</div>
          <div v-if="showTrendPercent" class="percent both">{{ trendPercent }}</div>
        </template>
        <template v-else>
          <div v-if="showTrendAbsolute" class="absolute">{{ trendAbsolute }}</div>
          <div v-if="showTrendPercent" class="percent">{{ trendPercent }}</div>
        </template>
      </div>
    </template>
  </ErrorStatisticsHeaderStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { buttonReset } from '@styles/mixins'

const ErrorStatisticsHeaderStyled = styled('div')`
  ${buttonReset}
  display: grid;
  grid-template-areas:
    'name'
    'value'
    'trend';
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  padding: 0;
  color: white;
  align-items: center;
  border-bottom: 5px solid ${p => (p.isSelected ? p.theme.colors.primary : 'transparent')};
  box-sizing: border-box;
  color: ${p => p.theme.colors.navFontNormal};
  background: ${p => p.theme.colors.headerBG};

  .name {
    grid-area: name;
    padding: 0.75rem 1rem 1rem 1rem;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &.small {
    .name {
      font-size: 12px;
      padding: 0.25;
    }
  }
  .value {
    grid-area: value;
    font-size: 18px;
    padding: 0 1rem;
    .unit {
      margin-left: 0.5rem;
      color: ${p => chroma(p.theme.colors.navFontNormal).darken(1).css()};
    }
  }
  .trend {
    display: flex;
    grid-area: trend;
    font-size: 14px;
    padding: 0.25rem 1rem;
    color: ${p => p.theme.colors.white};
    &.red {
      color: ${p => p.theme.colors.red};
    }
    &.grey {
      color: ${p => p.theme.colors.lightGrey};
    }
    &.green {
      color: ${p => p.theme.colors.green};
    }
    .percent.both {
      margin-left: 0.5rem;
    }
  }
  &:hover {
    color: ${p => p.theme.colors.navFontNormal};
  }
`
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: false,
    },
    smallTitle: {
      type: Boolean,
    },
    isOverview: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    showUnit: {
      type: Boolean,
      default: true,
    },
    unit: {
      type: String,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
    },
    trendRed: {
      type: Boolean,
      default: false,
    },
    showTrendAbsolute: {
      type: Boolean,
      default: false,
    },
    trendAbsolute: {
      type: String,
    },
    showTrendPercent: {
      type: Boolean,
      default: true,
    },
    trendPercent: {
      type: String,
    },
  },
  filters: {
    addPlusSignIfPositive(val) {
      const f = parseFloat(val)
      if (f > 0) {
        return `+${val}`
      }
      return val
    },
  },
  components: {
    ErrorStatisticsHeaderStyled,
  },
  computed: {
    trendGrey() {
      return parseInt(this.trendAbsolute) === 0
    },
  },
}
</script>
