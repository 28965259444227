<template>
  <AssetDimensionErrorStatisticsWidgetStyled>
    <ErrorStatisticsWidget
      :headerTiles="headerTiles"
      :isLoading="isLoading"
      @selectHeader="selectNotificationTab"
      :hasNoData="fetchedData.length === 0"
      :count="assetsSelected.length"
    >
      <template v-slot:header />
      <template v-slot:title>
        <h5>{{ selectedWidget.title }}</h5>
      </template>
      <template v-slot:main class="main">
        <keep-alive>
          <template v-if="fetchedData.length > 0">
            <StackedHorizontalBarChart
              v-if="selectedWidgetID === 'errorFrequency'"
              :severities="severities"
              :fetchedData="fetchedData"
              :dimensionMapped="dimensionMapped"
              :height="dynamicHeight"
            />
            <EvolutionOverTimeBarChart
              v-if="selectedWidgetID === 'evolutionOverTime'"
              :severities="severities"
              :fetchedData="fetchedData"
              :dimensionMapped="dimensionMapped"
            />
            <MachineComparisonBarChart
              v-if="selectedWidgetID === 'machineComparison'"
              :severities="severities"
              :fetchedData="fetchedData"
              :dimensionMapped="dimensionMapped"
              :assetsSelected="assetsSelected"
            />
            <CategoryComparisonBarChart
              v-if="selectedWidgetID === 'categoryComparison'"
              :severities="severities"
              :fetchedData="fetchedData"
              :dimensionMapped="dimensionMapped"
              :assetsSelected="assetsSelected"
            />
          </template>
          <NoDataStyled v-else>{{ message }}</NoDataStyled>
        </keep-alive>
      </template>
      <template v-slot:footer v-if="fetchedData.length > 0">
        <WidgetSelectorStyled
          v-for="widget in availableWidgetsMapped"
          :key="widget.id"
          :class="{ selected: widget.isSelected }"
          @click="setSelectedWidget(widget)"
        >
          <CircleIcon />
        </WidgetSelectorStyled>
      </template>
    </ErrorStatisticsWidget>
  </AssetDimensionErrorStatisticsWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import orderBy from 'lodash/orderBy'
import size from 'lodash/size'
import { TimeframeMixin } from '@common/mixins'
import localesMixin from '@/mixins/locales'
import { buttonReset } from '@styles/mixins'
import StackedHorizontalBarChart from '@/components/Atomic/Molecules/ErrorStatistics/Charts/StackedHorizontalBarChart'
import EvolutionOverTimeBarChart from '@/components/Atomic/Molecules/ErrorStatistics/Charts/EvolutionOverTimeBarChart'
import MachineComparisonBarChart from '@/components/Atomic/Molecules/ErrorStatistics/Charts/MachineComparisonBarChart'
import CategoryComparisonBarChart from '@/components/Atomic/Molecules/ErrorStatistics/Charts/CategoryComparisonBarChart'
import ErrorStatisticsWidget from '@/components/Widgets/ErrorStatistics/ErrorStatisticsWidget'
import { CircleIcon } from 'vue-feather-icons'
import { get } from 'lodash'
import { useAssetStore } from '@/stores/assets'
import ASSET_COUNT_NOTIFICATIONS_QUERY from '#/graphql/assetNotifications/assetGroupedNotifications.gql'

const availableWidgets = [
  {
    id: 'errorFrequency',
    title: 'Error Frequency',
  },
  {
    id: 'evolutionOverTime',
    title: 'Evolution Over Time',
  },
  {
    id: 'machineComparison',
    title: 'Machine Comparison',
  },
  {
    id: 'categoryComparison',
    title: 'Category Comparison',
  },
]

const AssetDimensionErrorStatisticsWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
`
const WidgetSelectorStyled = styled('button')`
  ${buttonReset}
  border: none;
  .feather {
    stroke: ${p => p.theme.colors.primary};
    fill: none;
  }
  &.selected {
    .feather {
      stroke: ${p => p.theme.colors.primary};
      fill: ${p => p.theme.colors.primary};
    }
  }
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`
export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [TimeframeMixin, localesMixin],
  inject: ['theme'],
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  components: {
    WidgetSelectorStyled,
    ErrorStatisticsWidget,
    StackedHorizontalBarChart,
    EvolutionOverTimeBarChart,
    MachineComparisonBarChart,
    CategoryComparisonBarChart,
    CircleIcon,
    AssetDimensionErrorStatisticsWidgetStyled,
    NoDataStyled,
  },
  data() {
    return {
      selectedNotificationId: 'allMachineMessages',
      availableWidgets: availableWidgets,
      selectedWidgetID: availableWidgets[0].id,
      allNotifications: [],
      faults: [],
      warnings: [],
      groupedData: [],
    }
  },
  watch: {
    selectedNotification: {
      handler() {
        this.fetchGroupedData()
      },
    },
    selectedWidget: {
      handler() {
        this.fetchGroupedData()
      },
    },
  },
  computed: {
    legend() {
      return this.selectedNotification[0].id === 'allMachineMessages'
    },
    isLoading() {
      return this.$apollo.loading
    },
    severities() {
      let severityColors = []
      if (this.selectedNotificationId === 'faults') {
        severityColors = ['Red']
      } else if (this.selectedNotificationId === 'warnings') {
        severityColors = ['Yellow']
      } else {
        severityColors = ['Red', 'Yellow']
      }
      return severityColors
    },
    dynamicHeight() {
      const height = size(this.fetchedData) >= 10 ? size(this.fetchedData) * 5 : 0
      return height
    },
    assetsSelected() {
      return this.assetStore.assetsSelected()
    },
    selectedAssets() {
      return this.assetsSelected.filter(Boolean).map(asset => asset.id)
    },
    dimensionMapped() {
      return this.widget.dimensions.filter(d => d?.assetDimension?.name ?? '' === this.selectedDimension)[0]
    },
    selectedDimension() {
      return this.widget?.dimensions[0]?.assetDimension?.name ?? ''
    },
    availableWidgetsMapped() {
      return this.availableWidgets.map(w => ({
        ...w,
        title:
          w.id === 'errorFrequency'
            ? this.selectedDimension === 'notification_count'
              ? this.$t('errorStatistics.errorCount')
              : this.$t('errorStatistics.errorDuration')
            : this.$t('errorStatistics.' + w.id),
        isSelected: this.selectedWidgetID === w.id,
      }))
    },
    groupedNotifications() {
      return [this.allNotifications, this.faults, this.warnings]
    },
    selectedNotification() {
      return this.groupedNotifications.filter(notification => notification.id === this.selectedNotificationId)
    },
    fetchedData() {
      return orderBy(get(this.groupedData, `${this.selectedNotificationId}_${this.selectedWidgetID}`, []), ['count'], ['desc'])
    },
    headerTiles() {
      return this.groupedNotifications.map(notification => {
        const notificationId = notification.id
        const notificationName = notification.id ? this.$t('errorStatistics.' + notification.id) : ''
        const color = '#eee'
        let trendRed = true
        const total =
          notification?.count === undefined || notification?.count === null
            ? this.selectedDimension === 'notification_duration'
              ? '- h'
              : '-'
            : this.getConvertedDuration(notification.count, true, true, true)
        const units = this.getConvertedUnits()
        let trendAbsolute =
          notification?.variation === undefined || notification?.variation?.absolute === null
            ? '(-)'
            : this.convUnit(notification?.variation?.absolute, units.unitSI, units.unitUI, 1, false, true)
        trendAbsolute = notification?.variation?.absolute > 0 ? `+${trendAbsolute}` : trendAbsolute
        const trendPercent =
          notification?.variation === undefined || notification?.variation?.percent === null
            ? '(-)%'
            : ` (${notification?.variation?.absolute > 0 ? '+' : ''}${this.convUnit(
                notification?.variation?.percent,
                '%',
                '%',
                1,
                true,
                true,
              )})`
        if (this.increasingTrendColor !== 'NEUTRAL') {
          if (notification?.variation?.absolute < 0) {
            trendRed = this.increasingTrendColor === 'RED' ? !trendRed : trendRed
          } else if (notification?.variation?.absolute > 0) {
            trendRed = this.increasingTrendColor === 'RED' ? trendRed : !trendRed
          }
        }

        return {
          id: notificationId,
          name: notificationName,
          total,
          color,
          trendRed,
          isOverview: false,
          title: notificationName,
          isSelected: notificationId === this.selectedNotificationId,
          showTrendAbsolute: true,
          showTrendPercent: true,
          trendAbsolute,
          trendPercent,
        }
      })
    },
    selectedWidget() {
      return this.availableWidgetsMapped.find(w => w.isSelected)
    },
    assetDimensionAllMapping() {
      return this.selectedDimension === 'notification_count' ? 'notifications_count' : 'notifications_duration'
    },
    assetDimensionFaultsMapping() {
      return this.selectedDimension === 'notification_count' ? 'notifications_count_red' : 'notifications_duration_red'
    },
    assetDimensionWarningsMapping() {
      return this.selectedDimension === 'notification_count' ? 'notifications_count_yellow' : 'notifications_duration_yellow'
    },
    increasingTrendColor() {
      return this.dimensionMapped?.assetDimension?.increasingTrendColor ?? ''
    },
    message() {
      const dimensionName =
        this.selectedDimension === 'notification_count' ? this.$t('errorStatistics.errorCount') : this.$t('errorStatistics.errorDuration')
      return this.$t('messages.noDimensionData', { dimension: dimensionName })
    },
    selectedErrorTypeName() {
      return this.widget?.widgetSettings?.errorTypeName.length > 0 ? this.widget?.widgetSettings?.errorTypeName : ['J1939_NEW', 'OEM']
    },
  },
  methods: {
    selectNotificationTab(id) {
      this.selectedNotificationId = id
    },
    setSelectedWidget(widget) {
      this.selectedWidgetID = widget.id
    },
    getConvertedUnits() {
      const unitSI = this.dimensionMapped?.assetDimension?.physicalUnitSI ?? null
      const unitUIMetric = this.dimensionMapped?.assetDimension?.physicalUnitUIMetric ?? null
      const unitUIImperial = this.dimensionMapped?.assetDimension?.physicalUnitUIImperial ?? null
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      return { unitSI, unitUI }
    },
    getConvertedDuration(notificationData, showUnits, attachUnits, format) {
      let units = {}
      if (showUnits) {
        units = this.getConvertedUnits()
      }
      return this.convUnit(notificationData, units.unitSI, units.unitUI, 2, attachUnits, format)
    },
    fetchGroupedData() {
      const selectedNotification = this.selectedNotificationId
      const selectedWidget = this.selectedWidgetID
      let groupBy = []
      let assetDimensionName = ''
      if (this.selectedTimeframeParam && this.selectedAssets) {
        if (selectedWidget === 'errorFrequency') {
          groupBy = ['LABEL', 'SEVERITY']
        } else if (selectedWidget === 'evolutionOverTime') {
          groupBy = ['TIME', 'SEVERITY']
        } else if (selectedWidget === 'machineComparison') {
          groupBy = ['ASSET', 'SEVERITY']
        } else if (selectedWidget === 'categoryComparison') {
          groupBy = ['COMPONENT', 'SEVERITY']
        }
        if (selectedNotification === 'allMachineMessages') {
          assetDimensionName = this.assetDimensionAllMapping
        } else if (selectedNotification === 'faults') {
          assetDimensionName = this.assetDimensionFaultsMapping
        } else if (selectedNotification === 'warnings') {
          assetDimensionName = this.assetDimensionWarningsMapping
        }

        this.$apollo.addSmartQuery(`${selectedNotification}_${selectedWidget}`, {
          query: ASSET_COUNT_NOTIFICATIONS_QUERY,
          variables() {
            return {
              where: {
                assetDimension: {
                  name: assetDimensionName,
                },
                timeframe: this.selectedTimeframeParam,
                assets: {
                  id_in: this.selectedAssets,
                },
                groupBy: groupBy,
                filterBy: this.selectedErrorTypeName,
              },
            }
          },
          skip() {
            return !this.selectedTimeframeParam || this.selectedAssets.length === 0
          },
          result(res) {
            this.$set(this.groupedData, `${selectedNotification}_${selectedWidget}`, res?.data?.assetGroupedNotifications ?? [])
          },
          manual: true,
        })
      }
    },
  },
  apollo: {
    allNotifications: {
      query: ASSET_COUNT_NOTIFICATIONS_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: this.assetDimensionAllMapping,
            },
            timeframe: this.selectedTimeframeParam,
            assets: {
              id_in: this.selectedAssets,
            },
            groupBy: [],
            filterBy: this.selectedErrorTypeName,
          },
        }
      },
      skip() {
        return !this.selectedTimeframeParam || this.selectedAssets.length === 0
      },
      update(allNotificationsResponse) {
        const allNotifications = { ...allNotificationsResponse.assetGroupedNotifications[0] }
        allNotifications.notificationName = this.$t('errorStatistics.allMachineMessages')
        allNotifications.id = 'allMachineMessages'
        return allNotifications
      },
    },
    faults: {
      query: ASSET_COUNT_NOTIFICATIONS_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: this.assetDimensionFaultsMapping,
            },
            timeframe: this.selectedTimeframeParam,
            assets: {
              id_in: this.selectedAssets,
            },
            groupBy: [],
            filterBy: this.selectedErrorTypeName,
          },
        }
      },
      skip() {
        return !this.selectedTimeframeParam || this.selectedAssets.length === 0
      },
      update(faultsResponse) {
        const faults = { ...faultsResponse.assetGroupedNotifications[0] }
        faults.notificationName = this.$t('errorStatistics.faults')
        faults.id = 'faults'
        return faults
      },
    },
    warnings: {
      query: ASSET_COUNT_NOTIFICATIONS_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: this.assetDimensionWarningsMapping,
            },
            timeframe: this.selectedTimeframeParam,
            assets: {
              id_in: this.selectedAssets,
            },
            groupBy: [],
            filterBy: this.selectedErrorTypeName,
          },
        }
      },
      skip() {
        return !this.selectedTimeframeParam || this.selectedAssets.length === 0
      },
      update(warningsResponse) {
        const warnings = { ...warningsResponse.assetGroupedNotifications[0] }
        warnings.notificationName = this.$t('errorStatistics.warnings')
        warnings.id = 'warnings'
        return warnings
      },
    },
  },
}
</script>
