var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionErrorStatisticsWidgetStyled', [_c('ErrorStatisticsWidget', {
    attrs: {
      "headerTiles": _vm.headerTiles,
      "isLoading": _vm.isLoading,
      "hasNoData": _vm.fetchedData.length === 0,
      "count": _vm.assetsSelected.length
    },
    on: {
      "selectHeader": _vm.selectNotificationTab
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_c('h5', [_vm._v(_vm._s(_vm.selectedWidget.title))])];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('keep-alive', [_vm.fetchedData.length > 0 ? [_vm.selectedWidgetID === 'errorFrequency' ? _c('StackedHorizontalBarChart', {
          attrs: {
            "severities": _vm.severities,
            "fetchedData": _vm.fetchedData,
            "dimensionMapped": _vm.dimensionMapped,
            "height": _vm.dynamicHeight
          }
        }) : _vm._e(), _vm.selectedWidgetID === 'evolutionOverTime' ? _c('EvolutionOverTimeBarChart', {
          attrs: {
            "severities": _vm.severities,
            "fetchedData": _vm.fetchedData,
            "dimensionMapped": _vm.dimensionMapped
          }
        }) : _vm._e(), _vm.selectedWidgetID === 'machineComparison' ? _c('MachineComparisonBarChart', {
          attrs: {
            "severities": _vm.severities,
            "fetchedData": _vm.fetchedData,
            "dimensionMapped": _vm.dimensionMapped,
            "assetsSelected": _vm.assetsSelected
          }
        }) : _vm._e(), _vm.selectedWidgetID === 'categoryComparison' ? _c('CategoryComparisonBarChart', {
          attrs: {
            "severities": _vm.severities,
            "fetchedData": _vm.fetchedData,
            "dimensionMapped": _vm.dimensionMapped,
            "assetsSelected": _vm.assetsSelected
          }
        }) : _vm._e()] : _c('NoDataStyled', [_vm._v(_vm._s(_vm.message))])], 2)];
      },
      proxy: true
    }, _vm.fetchedData.length > 0 ? {
      key: "footer",
      fn: function fn() {
        return _vm._l(_vm.availableWidgetsMapped, function (widget) {
          return _c('WidgetSelectorStyled', {
            key: widget.id,
            class: {
              selected: widget.isSelected
            },
            on: {
              "click": function click($event) {
                return _vm.setSelectedWidget(widget);
              }
            }
          }, [_c('CircleIcon')], 1);
        });
      },
      proxy: true
    } : null], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }